<template>
  <div style="cursor: pointer !important">
    <v-card class="mx-auto" outlined>
      <ListSkeleton v-if="listSkeleton" />
      <v-card-text class="text-h5" :class="type ? 'px-2 pt-0' : 'pa-0'" v-else-if="getBookkeepingRecords">
        <v-card-text class="pa-0 text-end" v-if="type">
          <v-icon
            class="mt-2 primary--text"
            @click="$emit('update:bookkeepingRecordsDialog', false)"
            >mdi-close</v-icon
          >
        </v-card-text>
        <v-data-table
          :dense="true"
          class="custom-table mb-0 ma-2 row-pointer"
          :headers="headers"
          fixed-header
          height="250px"
          :items="getBookkeepingRecords"
          style="word-break: break-word, overflow: auto"
          item-class="py-4"
          hide-default-footer
          :search="search"
          disable-pagination
        >
          <template v-slot:[`item.document_details`]="props">
            {{ $t(`bookkeepingRecords.${props.item.document_details.split("-")[0].toLowerCase()}`) }}-{{ props.item.document_details.split("-")[1] }}
          </template>
          <template v-slot:[`item.reference_one_date`]="props">
           {{profileById.date_format ? formatDateDDMMYYYY(props.item.reference_one_date) : parseDateYYYYMMDD(props.item.reference_one_date)}}
          </template>
          <template v-slot:[`item.reference_two_date`]="props">
           {{profileById.date_format ? formatDateDDMMYYYY(props.item.reference_two_date) : parseDateYYYYMMDD(props.item.reference_two_date)}}
          </template>
          <template v-slot:[`item.updated_at`]="{ item }">
            <span>{{profileById.date_format ? formatDateDDMMYYYY(new Date(item.updated_at).toLocaleString('sv-SE').substring(0,10)) + new Date(item.updated_at).toLocaleString('sv-SE').substring(10):new Date(item.updated_at).toLocaleString('sv-SE')}}</span>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            <span>{{profileById.date_format ? formatDateDDMMYYYY(new Date(item.created_at).toLocaleString('sv-SE').substring(0,10)) + new Date(item.created_at).toLocaleString('sv-SE').substring(10):new Date(item.created_at).toLocaleString('sv-SE')}}</span>
          </template>
          <template v-slot:[`item.amount`]="props">
           {{(props.item.amount).toFixed(2)}}
          </template>
          <template v-slot:top>
            <div class="text-body-2 primary--text mb-2">
              <v-row :dense="true">
                <v-col cols="9"> </v-col>
                <v-col cols="3" class="text-end d-flex">
                  <v-text-field
                    :full-width="false"
                    :dense="true"
                    hide-details="auto"
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$t('common.search')"
                    single-line
                  />
                </v-col>
              </v-row>
            </div>
            <v-divider />
          </template>
        </v-data-table>
        <v-row class="d-flex" style="width: 75% !important">
          <v-col class="mx-auto" cols="12">
            <span class="pl-3 pt-2 text-subtitle-2">{{ $t('common.numberOfTotalRows') }} {{getBookkeepingRecords.length}}</span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text class="text-caption text-center" v-else>{{ $t("common.noDataFound") }}</v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ListSkeleton from "@/components/skeleton/ListSkeleton";
import {formatDateDDMMYYYY, parseDateYYYYMMDD} from '@/utils';

export default {
  name: "ActivityLogTable",
  props: ["listSkeleton", 'type', 'bookkeepingRecordsDialog', 'docMode', 'docId'],
  components: {
    ListSkeleton,
  },
  data() {
    return {
      search: this.$route.query.search || "",
    };
  },
  methods: {
    formatDateDDMMYYYY: formatDateDDMMYYYY,
    parseDateYYYYMMDD: parseDateYYYYMMDD,
    async paginate() {
      let filter = null;
      if (this.docMode) {
        filter = {
          type: this.type,
          id: this.docId
        }
      }
      await this.$store.dispatch("GetBookkeepingRecords", filter);
    },
  },
  async mounted() {
    this.$emit("update:listSkeleton", true);
    let params = null;
    if (this.docMode) {
      params = {
        type: this.type,
        id: this.docId
      }
    }
    await this.$store.dispatch("GetBookkeepingRecords", params);
    await this.$store.dispatch('profile/GetCompanyById');
    this.$emit("update:listSkeleton", false);
  },
  watch: {
    params: {
      handler() {
        this.paginate();
      },
      deep: true,
    },
  },
  computed: {
    params() {
      return {
        ...this.pagination,
        query: this.search,
      };
    },
    headers() {
      return [
        { text: this.$t("bookkeepingRecords.journalEntry"), value: "journal_entry", width: "120px", sortable: false },
        { text: this.$t("bookkeepingRecords.documentDetails"), value: "document_details", width: "140px", sortable: false },
        { text: this.$t("bookkeepingRecords.referenceOne"), value: "reference_one", width: "110px", sortable: false },
        { text: this.$t("bookkeepingRecords.referenceOneDate"), value: "reference_one_date", width: "180px", sortable: false },
        { text: this.$t("bookkeepingRecords.referenceTwo"), value: "reference_two", width: "140px", sortable: false },
        { text: this.$t("bookkeepingRecords.referenceTwoDate"), value: "reference_two_date", width: "180px", sortable: false },
        { text: this.$t("bookkeepingRecords.year"), value: "year", width: "100px", sortable: false },
        { text: this.$t("bookkeepingRecords.title"), value: "title", width: "140px", sortable: false },
        { text: this.$t("bookkeepingRecords.debitAccount"), value: "debit_account", width: "140px", sortable: false },
        { text: this.$t("bookkeepingRecords.creditAccount"), value: "credit_account", width: "140px", sortable: false },
        { text: this.$t("bookkeepingRecords.amount"), value: "amount", width: "100px", sortable: false },
        { text: this.$t("bookkeepingRecords.createdAt"), value: "created_at", width: "200px", sortable: false },
        { text: this.$t("bookkeepingRecords.employee"), value: "employee", width: "140px", sortable: false },    
      ]
    },
    ...mapGetters({
      getBookkeepingRecords: "getBookkeepingRecords",
      profileById: 'profile/profileById',
    }),
  },
  beforeDestroy() {
    this.$store.commit('SET_BOOKKEEPING_RECORDS', []);
  }
};
</script>
<style scoped>
::v-deep .v-pagination__item {
  word-break: keep-all;
}
::v-deep .custom-table ::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
</style>