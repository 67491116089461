<template>
  <div>
    <v-dialog
      v-model="dialog"
      @click:outside="$emit('update:dialog', false);"
      max-width="60%"
    >
      <BankListTable :listSkeleton.sync="listSkeleton" @selectReceiptBank="selectReceiptBank" :type.sync="type" :sortByName="true">
        <template v-slot:closeIcon>
          <v-card-text class="pa-0 text-end">
            <v-icon class="mt-2 primary--text" @click="$emit('update:dialog', false);">mdi-close</v-icon>
          </v-card-text>
        </template>
      </BankListTable>
    </v-dialog>
  </div>
</template>
<script>
import BankListTable from '@/views/companyApp/bank/BankListTable';
export default {
  name: "BankDialog",
  props: ["dialog"],
  components: {
    BankListTable
  },
  data() {
    return {
      listSkeleton: false,
      type: 'ReceiptBank',
    }
  },
  watch: {
    dialog(val) {
      if(!val) {
        this.type = null;
      } else {
        this.type = 'ReceiptBank';
        this.$store.dispatch('bank/GetBank', {page: 1, limit: 10, order_by: 'name|asc'});
      }
    }
  },
  methods: {
    selectReceiptBank(item) {
      this.$emit('onBankChange', item);
      this.$emit('update:dialog', false);
    }
  },
}
</script>