<template>
  <v-dialog v-model="dialog" width="500" @click:outside="$emit('update:dialog', false);">
    <v-card>
      <v-card-title class="primary--text px-4 py-1 text-body-1">
        {{ $t('auxiliaryTable.confirm') }}
        <v-spacer />
        <v-btn
          icon
          @click="$emit('update:dialog', false);"
        >
          <v-icon color="primary darken-1">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="text-body-1 mt-4">
        {{ $t('common.doYouWant') }}<br />
        {{ $t('common.pleaseClickOnBelowButton') }}
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          class="text-capitalize"
          outlined
          small
          @click="closeStatus"
        >
          {{ $t('common.closeStatus') }}
        </v-btn>
        <v-btn
          color="primary"
          class="text-capitalize"
          small
          @click="openStatus"
        >
          {{ $t('common.openStatus') }}
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "StatusConfirmationDialog",
  props: ["dialog", "setOrderStatus"],
  methods: {
    openStatus(){
      this.$emit('update:setOrderStatus', 0);
      this.$emit('update:dialog', false);
      this.$emit('changeStatus');
    },
    closeStatus(){
      this.$emit('update:setOrderStatus', 1);
      this.$emit('update:dialog', false);
      this.$emit('changeStatus');
    }
  }
}
</script>